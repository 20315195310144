/**
 * Video Modal
 * @description : Modal to play a video.
 */

export default function setUpVideoModal(rootEl) {
    console.log(rootEl);
    const closeButtonEl = rootEl.querySelector('.js-video-modal__close');
    closeButtonEl.addEventListener('click', () => {
        rootEl.toggleAttribute('hidden', true);
        setTimeout(() => {
            allIframeEls.forEach(iframeEl => {
                iframeEl.toggleAttribute('hidden', true);
                iframeEl.src = 'about:blank';
            });
            window.removeEventListener('keydown', handleKeyDown);
        }, 300);
    });

    const vimeoIframeEl = rootEl.querySelector('.js-video-modal__vimeo-iframe');
    const youTubeIframeEl = rootEl.querySelector('.js-video-modal__youtube-iframe');
    const allIframeEls = [vimeoIframeEl, youTubeIframeEl];

    const infoBarEl = rootEl.querySelector('.js-video-modal__info-bar');
    const headingBarEl = rootEl.querySelector('.js-video-modal__heading-bar');

    const titleEl = rootEl.querySelector('.js-video-modal__title');
    const titleLabelEl = rootEl.querySelector('.js-video-modal__title-label');
    const captionEl = rootEl.querySelector('.js-video-modal__caption');

    const triggerEls = Array.from(document.querySelectorAll('.js-video-modal__trigger'));
    triggerEls.forEach(triggerEl => {
        const videoId = triggerEl.getAttribute('data-video-id').trim();

        const videoPlatformIsVimeo = Boolean(videoId.match(/^[0-9]+$/));
        const videoPlatformIsYouTube = Boolean(videoId.match(/^[a-z0-9_-]+$/i));

        if (!videoPlatformIsVimeo && !videoPlatformIsYouTube) {
            console.error(`The value "${videoId}" is not a valid Vimeo or YouTube video ID`);
        }

        const iframeEl = videoPlatformIsVimeo ? vimeoIframeEl : youTubeIframeEl;
        const iframeSrcTemplate = iframeEl.getAttribute('data-src');

        const title = triggerEl.getAttribute('data-video-title');
        const titleLabel = triggerEl.getAttribute('data-video-title-label');
        const caption = triggerEl.getAttribute('data-video-caption');

        triggerEl.addEventListener('click', () => {
            console.log(triggerEl)
            iframeEl.src = iframeSrcTemplate.replace('[ID]', videoId);
            iframeEl.title = title;

            titleEl.textContent = title;
            titleLabelEl.textContent = titleLabel;
            captionEl.innerHTML = caption;

            titleEl.toggleAttribute('hidden', !title);
            titleLabelEl.toggleAttribute('hidden', !titleLabel);
            captionEl.toggleAttribute('hidden', !caption);

            infoBarEl.toggleAttribute('hidden', !title && !titleLabel && !caption);
            headingBarEl.toggleAttribute('hidden', !title && !titleLabel);

            iframeEl.toggleAttribute('hidden', false);
            rootEl.toggleAttribute('hidden', false);

            window.addEventListener('keydown', handleKeyDown);
        });
    });

    function handleKeyDown(event) {
        if (event.key === 'Escape') {
            const clickEvent = new MouseEvent('click', { bubbles: true });
            closeButtonEl.dispatchEvent(clickEvent);
        }
    }

};