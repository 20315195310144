﻿/**
 * Iframe resizer
 * @description : 
 */

export default function setUpIframeResizer(rootEl) {
    const iframeEl = rootEl.querySelector('iframe');

    if (iframeEl) {
        let width = iframeEl.getAttribute('width') ? iframeEl.getAttribute('width') : 900; // Default width is 900
        let height = iframeEl.getAttribute('height') ? iframeEl.getAttribute('height') : 500; // Default height is 500
                        
        let aspectRatio = ((height / width) * 100).toFixed(4);
        rootEl.style = "--iframe-height: " + aspectRatio + "%;";

        // Remove width and height attributes
        iframeEl.removeAttribute('width');
        iframeEl.removeAttribute('height');
    }
}